import {
  ArticlePageFieldsFragment,
  TopicListItemFieldsFragment,
  TopicOverviewPageFieldsFragment,
} from '../../../../__generated__/datocms.types'
import {validateDatoCmsTypes} from './common.serde'
import {parseRichTextContent} from './rich-text.serde'
import {PUBLIC_ROUTES} from '../../../shared/routing/public.routes'
import {LinkService} from '../../i18n/link.service'
import {ArticleHeaderImage, ArticlePage, ArticlePreview, TopicListItem, TopicOverviewPage} from '../types/article.types'
import {parseAuthorCollection} from './author.serde'
import {parseCallToAction} from './call-to-action.serde'
import {parsePageMeta} from './seo.serde'
import {fromSiteLocale} from '../site-locale'
import {
  ArticleOverviewPage_ArticleFieldsFragment,
  TopicOverviewPage_ArticleFragment,
} from '../../../../__generated__/types'

export function parseTopicOverviewPage(
  e: TopicOverviewPageFieldsFragment,
  moreTopics: TopicListItemFieldsFragment[],
  linkService: LinkService,
): TopicOverviewPage {
  const entry = validateDatoCmsTypes(e)

  return {
    title: entry.title,
    slug: entry.slug,
    breadcrumbTitle: entry.breadcrumbTitle,
    description: entry.description,
    moreTopics: moreTopics.map(t => parseTopicListItem(t, linkService)),
  }
}

export function parseArticlePage(e: ArticlePageFieldsFragment, linkService: LinkService): ArticlePage {
  const entry = validateDatoCmsTypes(e)

  return {
    slug: entry.slug,
    activeLocales: entry._locales.map(fromSiteLocale),
    parent: entry.parentField
      ? {
          title: entry.parentField.title!,
          breadcrumbTitle: entry.parentField.breadcrumbTitle,
          path: linkService.localizedPathTo(PUBLIC_ROUTES.SERVICE_OVERVIEW_PAGE, {
            routeParams: {pageSlug: entry.parentField.slug!},
          }),
        }
      : undefined,
    topic: entry.topic
      ? {
          title: entry.topic.title!,
          breadcrumbTitle: entry.topic.breadcrumbTitle,
          slug: entry.topic.slug,
          path: linkService.localizedPathTo(PUBLIC_ROUTES.TOPIC_OVERVIEW_PAGE, {
            routeParams: {pageSlug: entry.topic.slug!},
          }),
        }
      : undefined,
    title: entry.title,
    breadcrumbTitle: entry.breadcrumbTitle,
    preview: entry.preview,
    headerImage: entry.headerImage ? parseArticleHeader(entry) : undefined,
    readingTime: entry.readingTime,
    // use the release field here because it's accurate from before the content migration
    // while the _firstPublishedAt and _publishedAt fields are dates of the content migration ...
    releasedAt: entry.release ? new Date(entry.release) : undefined,
    lastPublishedAt: entry._publishedAt ? new Date(entry._publishedAt) : undefined,
    content: parseRichTextContent(entry),
    authors: parseAuthorCollection(entry.author),
    mainCallToAction: entry.mainCallToAction ? parseCallToAction(entry.mainCallToAction) : undefined,
    secondaryCallToAction: entry.secondaryCallToAction ? parseCallToAction(entry.secondaryCallToAction) : undefined,
    meta: entry.seo ? parsePageMeta(linkService, entry.seo) : undefined,
  }
}

export function parseTopicListItem(item: TopicListItemFieldsFragment, linkService: LinkService): TopicListItem {
  const entry = validateDatoCmsTypes(item)
  return {
    id: entry.id,
    slug: entry.slug,
    title: entry.title,
    breadcrumbTitle: entry.breadcrumbTitle,
    path: linkService.localizedPathTo(PUBLIC_ROUTES.TOPIC_OVERVIEW_PAGE, {routeParams: {pageSlug: entry.slug}}),
  }
}

export function parseArticleHeader(
  article: Pick<ArticlePageFieldsFragment, 'headerImage'>,
): ArticleHeaderImage | undefined {
  const {headerImage} = article
  if (!headerImage) {
    return undefined
  }

  return {
    url: headerImage.url!,
    title: headerImage.title,
  }
}

export function parseArticlePreview(
  a: TopicOverviewPage_ArticleFragment | ArticleOverviewPage_ArticleFieldsFragment,
  linkService: LinkService,
): ArticlePreview {
  const author = a.author?.length ? a.author[0] : undefined
  return {
    slug: a.slug,
    title: a.title,
    readingTime: a.readingTime,
    topic: a.topic?.breadcrumbTitle || a.topic?.title,
    headerImage: a.bannerImage ? {title: a.bannerImage.title || a.title, url: a.bannerImage.file} : undefined,
    author: author
      ? {
          name: author.name,
          portrait: author.portrait
            ? {url: author.portrait.url, title: author.portrait.title || author.name}
            : undefined,
        }
      : undefined,
    path: linkService.localizedPathTo(PUBLIC_ROUTES.ARTICLE_PAGE, {routeParams: {pageSlug: a.slug}}),
  }
}
